<template>
  <div class="overflow-hidden bg-gray-900 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div
        class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2"
      >
        <div class="lg:pr-8 lg:pt-4">
          <div class="lg:max-w-lg">
            <h2 class="text-3xl font-semibold leading-7 text-action">Über uns</h2>
            <h3 class="mt-2 text-3xl font-bold text-white leading-[120%] sm:text-6xl">
              Aus dem Handwerk für das Handwerk
            </h3>

            <p class="mt-6 text-lg leading-8 text-gray-300">
              Wir verstehen die Herausforderungen des Handwerks aus erster Hand. Unser Mitgründer
              Benjamin Gropler führt seit vielen Jahren die Nordcon Bau und Sanierung GmbH, einen
              Handwerksbetrieb. Seine Erfahrungen in der Auftragsgewinnung haben uns gezeigt, dass
              es nicht nur darum geht, Aufträge zu erhalten, sondern vor allem um gute Aufträge.
              Gute Aufträge mit klaren Umfängen und einer transparenten Gewinnkalkulation. Unsere
              Vision ist es, Handwerkern die Kontrolle über ihre Aufträge zurückzugeben. Das
              bedeutet für uns klare Planung, transparente Auftragsabwicklung und vor allem
              vorhersehbare Umsätze.
            </p>

            <p class="mt-6 text-lg leading-8 text-gray-300">
              Die Geschichte unseres Unternehmens im Handwerk ist unsere Antriebskraft. Wir
              entwickeln digitale Lösungen, weil wir die täglichen Hürden im Handwerk kennen und
              verstehen. Handwerker können darauf vertrauen, dass unsere Tools echte Probleme lösen
              und den Arbeitsalltag erleichtern. Wir sind keine Außenstehenden im Handwerk – wir
              sind einer von euch.
            </p>
          </div>
        </div>
        <NuxtImg
          src="/images/arts/aus-fuer.png"
          alt="Handwerker bei der Arbeit glücklich"
          loading="lazy"
          class="w-[25rem] max-w-none rounded-xl shadow-xl ring-1 ring-white/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
          width="2432"
          height="1842"
        />
      </div>
    </div>
  </div>
</template>
