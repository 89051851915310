<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import anime from 'animejs';
import { useIntersectionObserver } from '@vueuse/core';

const props = defineProps({
  value: {
    type: Number,
    required: true,
  },
  duration: {
    type: Number,
    default: 700,
  },
  format: {
    type: String,
    default: 'none',
    validator: (value) => ['currency', 'number', 'none'].includes(value),
  },
});

const count = ref(0);
const target = ref(null);

const isInView = ref(false);
const { stop } = useIntersectionObserver(
  target,
  ([{ isIntersecting }]) => {
    isInView.value = isIntersecting;
  },
  { threshold: 0.5 }
);

const formattedCount = computed(() => {
  switch (props.format) {
    case 'currency':
      return count.value.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
    case 'number':
      return count.value.toLocaleString('de-DE', {
        maximumFractionDigits: 0,
      });
    default:
      return count.value.toString();
  }
});

const animateCount = (targetValue) => {
  if (isInView.value) {
    anime({
      targets: { value: count.value },
      value: targetValue,
      duration: props.duration,
      easing: 'linear',
      round: 1,
      update: (anim) => {
        count.value = anim.animations[0].currentValue;
      },
    });
  }
};

watch(
  () => props.value,
  (newValue) => {
    animateCount(newValue);
  }
);

watch(isInView, (newValue) => {
  if (newValue) {
    animateCount(props.value);
  }
});

onMounted(() => {
  if (isInView.value) {
    animateCount(props.value);
  }
});
</script>

<template>
  <span ref="target">{{ formattedCount }}</span>
</template>
