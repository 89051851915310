<template>
  <div class="py-14 overflow-hidden">
    <div class="container">
      <div
        class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2"
      >
        <div class="lg:ml-auto lg:pl-4 lg:pt-4">
          <div class="lg:max-w-xl">
            <h2 class="text-xl leading-7 text-primary-500">Dein Betrieb, deine Regeln</h2>
            <p class="mt-2 text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Passgenaue Anfragen von Sanierungskunden
            </p>
            <p class="mt-6 text-lg leading-7 text-gray-600">
              Verwalte spielend leicht deine Kundenanfragen, behalte den Überblick über
              Kundenanfragen und analysiere erfolgreich abgeschlossene Aufträge – alles auf einen
              Blick. Mit deiner persönlichen Plattform für die Anfragenverwaltung.
            </p>
            <dl
              class="mt-10 ml-10 max-w-xl space-y-8 text-base leading-6 text-gray-600 lg:max-w-none"
            >
              <div
                v-for="feature in features"
                :key="feature.name"
                class="relative pl-12 pr-4 lg:pr-14 pb-7 rounded-tr-[50px] lg:rounded-tr-[150px] rounded-bl-[100px] lg:rounded-bl-[100px] bg-primary-50/10 p-4"
              >
                <div
                  class="bg-primary-400 border-primary-500/30 border-[15px] rounded-full absolute -left-10 top-0 p-1"
                >
                  <Icon :name="feature.icon" class="h-7 w-7 text-action" aria-hidden="true" />
                </div>

                <dt class="inline font-semibold text-gray-900">{{ feature.name }}:</dt>
                {{ ' ' }}
                <dd class="inline">{{ feature.description }}</dd>
              </div>
            </dl>
          </div>
        </div>
        <div class="flex items-start justify-end lg:order-first">
          <NuxtImg
            src="/images/screens/dashboard-v2.png"
            alt="moreathome.de dashboard settings"
            class="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[70rem]"
            width="2832"
            height="1442"
            sizes="100vw sm:50vw md:2432px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const features = [
  {
    name: 'Deine Gewerke',
    description:
      'Konzentriere dich auf dein Spezialgebiet und begeistere Kunden in deiner Nische. Erhalte ausschließlich perfekt passende Anfragen und wähle vorab deine Gewerke aus.',
    icon: 'heroicons:cog',
  },
  {
    name: 'Dein Einzugsgebiet',
    description:
      'Nur du weißt, wie weit du fahren willst. Das Einzugsgebiet kannst du jederzeit über den Radius anpassen.',
    icon: 'heroicons:map-pin',
  },
  {
    name: 'Deine Kontrolle',
    description:
      'Dank Datenexport kannst du Daten einfach exportieren und in deiner bevorzugten Handwerkersoftware nutzen.',
    icon: 'heroicons:arrow-top-right-on-square-20-solid',
  },
];
</script>
