<template>
  <div class="py-14 overflow-hidden">
    <div class="container">
      <div
        class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2"
      >
        <div class="lg:pr-8 lg:pt-4">
          <div class="lg:max-w-xl">
            <h2 class="text-xl leading-7 text-primary-500">So wird dein Handwerk digital</h2>
            <p class="mt-2 text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Zeit fürs Handwerk, statt Überstunden im Büro
            </p>
            <p class="mt-6 text-lg leading-7 text-gray-600">
              Alles auf einen Blick! In deinem persönlichen Dashboard findest du alles, was du für
              Dein Wachstum brauchst. Alle Kundenanfragen, alle Kontaktdaten, deine möglichen Margen
              und noch vieles mehr!
            </p>
            <dl
              class="mt-10 ml-10 max-w-xl space-y-8 text-base leading-6 text-gray-600 lg:max-w-none"
            >
              <div
                v-for="feature in features"
                :key="feature.name"
                class="relative pl-12 pr-4 lg:pr-14 pb-7 rounded-tr-[50px] lg:rounded-tr-[150px] rounded-bl-[100px] lg:rounded-bl-[100px] bg-primary-50/10 p-4"
              >
                <div
                  class="bg-primary-400 border-primary-500/30 border-[15px] rounded-full absolute -left-10 top-0 p-1"
                >
                  <component :is="feature.icon" class="h-7 w-7 text-action" aria-hidden="true" />
                </div>

                <dt class="inline font-semibold text-gray-900">{{ feature.name }}:</dt>
                {{ ' ' }}
                <dd class="inline">{{ feature.description }}</dd>
              </div>
            </dl>
          </div>
        </div>
        <NuxtImg
          src="/images/screens/dashboard-v2.png"
          alt="Dashboard moreathome.de"
          class="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[70rem]"
          width="2832"
          height="1442"
          sizes="100vw sm:50vw md:2432px"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  PresentationChartBarIcon,
  ChevronDoubleUpIcon,
  RectangleGroupIcon,
} from '@heroicons/vue/24/outline';

const features = [
  {
    name: 'Anfragen erhalten',
    description:
      'Auf Knopfdruck generierst du qualifizierte Kundenanfragen, die nach einem Leistungskatalog strukturiert sind. Du bestimmst und kennst Umsatz und Marge von Anfang an.',
    icon: RectangleGroupIcon,
  },
  {
    name: 'Auftrag abschließen',
    description:
      'Über das Dashboard siehst du alle relevanten Informationen bereits vor Vertragsabschluss. Der Abschluss erfolgt einfach - der Sanierungskunde kennt bereits vorher den ungefähren Preisumfang seiner Sanierung – du hast die Kontrolle über den gesamten Prozess und muss nur noch überzeugen.',
    icon: ChevronDoubleUpIcon,
  },
  {
    name: 'Digital verwalten',
    description:
      'Für noch tiefere Einblicke kannst du die Daten exportieren und direkt in deiner favorisierten Software analysieren. Gewinne Kontrolle über dein Geschäft, verbessere deine Prozesse und steigere systematisch dein Umsatz.',
    icon: PresentationChartBarIcon,
  },
];
</script>
