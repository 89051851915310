<script setup>
import { useElementVisibility } from '@vueuse/core';
import { ref } from 'vue';
import ThreeBoxes from '../components/Home/ThreeBoxes.vue';

const target = ref(null);
const targetIsVisible = useElementVisibility(target);

useHead({
  title: 'Sanierungsaufträge im Handumdrehen',
  meta: [
    {
      name: 'description',
      content:
        'Steigere deinen Umsatz mit exklusiven Sanierungsaufträgen! Unsere Plattform generiert gezielte Kundenanfragen für Handwerker. Jetzt Kundenanfragen sichern!',
    },
  ],
});
</script>

<template>
  <HomeIntro2 />

  <div class="container my-20">
    <ThreeBoxes />
  </div>

  <HomeVideoWindow />

  <HomeTeaserWindow />

  <HomeFeature1 />
  <HomeFeature2 />

  <HomeSteps />

  <div ref="target" class="container">
    <HomeReviews v-if="targetIsVisible" />
  </div>
  <HomeMoreTextWindow />

  <div class="container">
    <h2 class="text-3xl sm:text-4xl text-center mt-20">Neue Anfragen, mehr Umsatz: Bereit?</h2>

    <HomeCta center label="Jetzt anmelden!" class="pb-0" />

    <HomeWhyWindow />
  </div>

  <HomeFaqs />
</template>
