
import * as vercelRuntime$ebCl1EST7g from '/vercel/path1/node_modules/.pnpm/@nuxt+image@1.8.0_rollup@4.24.0/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 2536
  },
  "presets": {
    "hero": {
      "modifiers": {
        "format": "webp",
        "quality": "80"
      }
    },
    "card": {
      "modifiers": {
        "format": "webp",
        "quality": "80"
      }
    }
  },
  "provider": "vercel",
  "domains": [],
  "alias": {
    "/unsplash": "https://images.unsplash.com",
    "/tailwind": "https://tailwindui.com",
    "/contentful": "https://images.ctfassets.net"
  },
  "densities": [
    1,
    2,
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$ebCl1EST7g, defaults: {} }
}
        