<template>
  <div class="grid lg:grid-cols-5 mt-20">
    <div class="lg:col-span-2">
      <NuxtImg
        title="Handwerker"
        class="w-full max-w-xs"
        loading="lazy"
        width="320"
        sizes="100vw sm:50vw md:320px"
        src="/images/arts/handwerker.png"
        alt="Handwerker"
      />
    </div>
    <div class="lg:col-span-3">
      <div class="text-lg leading-[150%] bg-primary text-white rounded-2xl p-10 mb-10 lg:mb-0">
        <p class="mb-5">
          Als Handwerker weißt du genau, wie mühsam es sein kann, neue Aufträge zu generieren. Die
          Suche nach neuen Kunden ist zeitaufwendig und oft auch mit hohen Kosten verbunden. Doch
          warum solltest du dich damit abmühen?
        </p>
        <p class="mb-5">
          Es gibt eine einfachere Lösung! Melde dich jetzt auf unserer Internetseite an und spare
          dir wertvolle Zeit sowie Kosten bei der Akquise von Neukunden.
        </p>
        <p class="mb-5">
          Nutze unsere Sanierungsanfragen, um deinen Erfolg als Handwerksbetrieb zu steigern. Wir
          garantieren dir nicht nur detaillierte Leistungsverzeichnisse, sondern auch höhere Chancen
          auf erfolgreiche Kundenaufträge.
        </p>
        <p>
          <b
            >Worauf wartest du noch? Registriere dich jetzt kostenlos auf unserer Seite und
            profitiere von den zahlreichen Vorteilen!</b
          >
        </p>
      </div>
    </div>
  </div>
</template>
