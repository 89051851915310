<template>
  <div class="py-14 bg-primary">
    <div class="container">
      <div class="lg:grid lg:grid-cols-12 lg:gap-12">
        <div class="lg:col-span-4 pb-10">
          <h2 class="text-6xl leading-[100%] text-white">Häufige Fragen</h2>
          <p class="mt-10 font-bold text-lg text-white">
            Du kannst die Antwort, die du suchst, nicht finden? Wende dich an unseren
            <a href="mailto:partner@moreathome.de" title="Kontakt" class="underline text-action"
              >Kundensupport</a
            >.
          </p>
          <a
            href="mailto:partner@moreathome.de"
            class="inline-block mt-10 font-bold bg-action rounded-[30px] px-10 py-4 text-black"
            >Kontaktiere uns</a
          >
        </div>
        <div class="lg:col-span-8 lg:mt-0">
          <dl class="border-t border-gray-200/70 space-y-8 divide-y divide-gray-200/70">
            <div v-for="faq in faqs" :key="faq.id" class="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt class="text-xl font-bold leading-7 text-white lg:col-span-5">
                {{ faq.question }}
              </dt>
              <dd class="mt-4 lg:col-span-7 lg:mt-0">
                <div class="text-base leading-7 text-gray-200" v-html="faq.answer"></div>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const faqs = [
  {
    question: 'Muss ich für die Registrierung etwas bezahlen?',
    answer:
      'Nein, die Registrierung auf unserer Plattform ist für dich völlig kostenlos. Wenn du Anfragen von uns erhalten möchtest, zahlst du lediglich eine Aktivierungsgebühr von 0,95 €.',
  },
  {
    question: 'Wie teuer sind die Anfragen?',
    answer:
      'Die Kosten für die Sanierungsanfragen auf unserer Internetseite variieren je nach dem Sanierungsaufwand. Die Preisspanne liegt dabei zwischen 29 Euro und 199 Euro. Die Preise sind nach Gewerk sowie nach Umfang und Größe der Sanierung gestaffelt. Eine genaue Auflistung der Preise erhältst du nach der Registrierung in deinem persönlichen Dashboard.',
  },
  {
    question: 'Kann ich meinen Account wieder löschen?',
    answer:
      'Du kannst deinen Account jederzeit deaktivieren oder löschen wenn es dir nicht gefällt. Dir stehen in deinem persönlichen Bereich mehrere Möglichkeiten zur Verfügung.',
  },
  {
    question: 'Bin ich verpflichtet Kundenanfragen abzunehmen?',
    answer:
      'Du hast in deinem persönlichen Dashboard jederzeit die Möglichkeit, die Anzahl der Kundenanfragen zu ändern. Je nachdem, ob du viele oder wenige Anfragen haben möchtest. Auch den Umkreis oder deine Gewerke kannst du jederzeit anpassen.',
  },
  {
    question: 'Gibt es eine feste Laufzeit?',
    answer:
      'Die Mindestlaufzeit beträgt nur einen Monat und kann jederzeit zum Ende des Monats gekündigt werden.',
  },
  {
    question: 'Was für Kundenanfragen erhalte ich?',
    answer:
      'Du erhältst hochwertige Kundenanfragen von Immobilienbesitzern die wirklich ihre Wohnung oder Haus sanieren wollen. Weiterhin erhältst du ein umfangreiches Leistungsverzeichnis, damit bereits vorher weisst, um was es geht.',
  },
  {
    question: 'Warum sollte ich mich bei more at home registrieren?',
    answer:
      '<p>Du solltest dich auf Ihrer Internetseite registrieren, weil du dadurch Zugang zu einer neuen Plattform erhältst, die einmalige Kundenanfragen für Sanierungsprojekte bietet. Unsere Plattform ist darauf ausgerichtet, Handwerkern qualifizierte und lohnenswerte Anfragen zu vermitteln.</p><p>Durch die Registrierung auf unserer Plattform hast du die Möglichkeit, deine Dienstleistungen einem spezifischen und engagierten Kundenstamm zu präsentieren, der wirklich daran interessiert ist, ihre Wohnung oder ihr Haus zu sanieren.</p><p>Weiterhin bietet unsere Plattform einen einfachen und effizienten Weg, um Kundenanfragen zu verwalten und zu kommunizieren.</p><p>Die Registrierung auf unserer Plattform ermöglicht es dir, deinen Kundenstamm zu erweitern, dein Geschäft auszubauen und neue Chancen zu nutzen. Mit unseren exklusiven Kundenanfragen hast du die Möglichkeit, qualitativ hochwertige und lohnende Projekte anzunehmen.</p>',
  },
];
</script>
