<template>
  <div class="overflow-hidden bg-gray-900 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div
        class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2"
      >
        <div class="lg:pr-8 lg:pt-4">
          <div class="lg:max-w-lg">
            <h2 class="text-3xl font-semibold leading-7 text-action">Schluss mit Unsicherheit</h2>
            <h3 class="mt-2 text-3xl font-bold text-white leading-[120%] sm:text-6xl">
              Planbare Kundenanfragen auf Knopfdruck
            </h3>

            <p class="mt-6 text-lg leading-8 text-gray-300">
              Du lebst in der Unsicherheit, von Auftrag zu Auftrag zu hangeln. Jeder neue Job birgt
              Überraschungen, und du fühlst dich gezwungen, jeden Auftrag anzunehmen, um die
              Unsicherheit zu minimieren. Das zwingt dich, viel Zeit in die Verwaltung zu stecken,
              Papierkram zu erledigen und ständig neue Arbeitsabläufe zu erklären. Das kostet nicht
              nur Zeit, sondern raubt auch die Energie, die du eigentlich für deine eigentliche
              Arbeit benötigst.
            </p>

            <p class="mt-6 text-lg leading-8 text-gray-300">
              Mit unserem Sanierungsrechner bietet wir Haus- und Wohnungseigentümern eine einfache
              Möglichkeit, die Kosten einer Sanierung online zu berechnen.
            </p>

            <p class="mt-6 text-lg leading-8 text-gray-300">
              Unsere Handwerker profitieren dadurch, indem wir ihnen qualifizierte Anfragen von
              Kunden vermitteln, die realistische Vorstellungen von ihren Sanierungsprojekten haben.
              Durch die gezielte Vorab-Information der Kunden, können unsere Handwerker effizienter
              arbeiten und haben mehr Planungssicherheit.
            </p>

            <p class="mt-6 text-lg leading-8 text-gray-300">
              Zudem bieten wir eine Plattform, auf der unsere Handwerker ihre Dienstleistungen einem
              breiten Publikum präsentieren können. So sparen sie Zeit und Ressourcen bei der
              Akquise neuer Projekte und können sich voll auf die Ausführung konzentrieren.
            </p>
          </div>
        </div>
        <NuxtImg
          src="/images/bg/handwerker.jpg"
          alt="Handwerker bei der Arbeit glücklich"
          loading="lazy"
          class="w-[25rem] max-w-none rounded-xl shadow-xl ring-1 ring-white/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
          width="2432"
          height="1842"
        />
      </div>
    </div>
  </div>
</template>
