<script setup>
const steps = [
  {
    title: 'Schritt 1',
    subtitle: 'Online anmelden',
    body: 'Im ersten Schritt registrierst du dich völlig kostenlos auf unserer Plattform. Teile uns mit, wie viele Anfragen aus welchen Gewerken du erhalten möchtest.',
  },
  {
    title: 'Schritt 2',
    subtitle: 'Details definieren',
    body: 'Nach deiner Registrierung erhältst du Zugang zu deinem persönlichen Dashboard und kannst weitere Einstellungen vornehmen. Unter anderem kannst du deine Zahlungsdaten eingeben, Preise einsehen und deine Qualifikationen speichern.',
  },
  {
    title: 'Schritt 3',
    subtitle: 'Aufträge generieren',
    body: 'Du erhältst über das Dashboard neue Anfragen für Sanierungen & Modernisierungen und kannst sofort loslegen. Gemeinsam setzen wir alles daran, dein Unternehmen auf neue Beine zu stellen.',
  },
];
</script>
<template>
  <div class="py-16 bg-primary text-white">
    <div class="container">
      <h2 class="text-3xl sm:text-5xl mb-5 text-center">Jetzt starten: So arbeiten wir zusammen</h2>

      <div class="lg:max-w-2xl mb-10 text-center mx-auto">
        <p class="text-lg leading-8">
          <b>Unser Versprechen an dich als Handwerker</b>: Mit unserem
          <b>einzigartigen Service</b>
          erhältst du Zugang zu
          <b>hochqualifizierten Anfragen</b> von Haus- und Wohnungseigentümern, die eine Sanierung
          oder Modernisierung ihrer Immobilie planen.
        </p>
      </div>

      <div class="grid sm:grid-cols-3 gap-x-10 gap-y-20">
        <div v-for="i in steps" class="text-lg">
          <h3
            class="flex items-center justify-center text-white mx-auto rounded-full text-2xl mb-4 h-[140px] w-[140px] border-4 border-action"
          >
            {{ i.title }}
          </h3>

          <div
            :class="i.info ? 'pb-10' : ''"
            class="border-4 border-action rounded-tr-[40px] rounded-bl-[40px] p-4"
          >
            <b class="block text-lg mb-2">{{ i.subtitle }}</b>
            {{ i.body }}
          </div>

          <div
            v-if="i.info"
            class="border-l-4 border-b-4 border-r-4 font-semibold border-t-2 border-action -mt-9 px-4 py-3 rounded-tr-[40px] rounded-bl-[40px] leading-5 text-white bg-gradient-to-r from-primary-300 to-primary-500 italic z-10 relative bg-white text-sm"
          >
            {{ i.info }}
          </div>
        </div>
      </div>

      <HomeCta center label="Jetzt Anfragen gewinnen" class="pb-0" />
    </div>
  </div>
</template>
